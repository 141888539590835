<div class="loading" *ngIf="loading$ | async">
  Loading...
</div>
<div class="filter">
  <form #form="ngForm">
    <label>
      Feed options
      <select name="feedFilter" ngModel>
        <option value="">All</option>
        <option value="onlyImages">Only Images</option>
        <option value="onlyText">Only Text</option>
      </select>
    </label>
  </form>
</div>
<div class="articles">
  <article #article [id]="item.id" *ngFor="let item of feed$ | async; trackBy: trackByFeedItem">
    <div class="user">
      <img [src]="item.user.avatar" [alt]="item.user.name + ' avatar'">
      {{item.user.name}}
    </div>
    <p *ngIf="item.type === 'text'">{{item.text}}</p>
    <img *ngIf="item.type === 'image'"
         [src]="item.imageURL"
         [alt]="item.imageURL">
  </article>
</div>
